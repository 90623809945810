<div id="courses">
    <div id="showcase">
        <img src="/assets/img//showcase/11.jpeg" alt="banner" class="img-fluid w-100">
    </div>
    <div id="heading">
        <h1>Courses</h1>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" routerLink="/"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Courses</a></li>
              <li class="breadcrumb-item active" aria-current="page" style="text-transform: capitalize;">{{courseName}}</li>
            </ol>
          </nav>
    </div>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <nav id="secondary-navigation" *ngIf="showSideMenu">
                        <!-- Header -->
                        <header class="header">
                            <h5>Program Type</h5>
                        </header>
                        <!-- /Header -->
                        <ul>
                                    <li routerLinkActive="active">
                                <a routerLink="/courses/arts" (click)="changeCourse('Humanities')" (click)="checkRoute()">Humanities</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/science"
                                (click)="changeCourse('Science')" (click)="checkRoute()">Science</a>
                            </li>
                                    <li routerLinkActive="active">
                                <a routerLink="/courses/commerce" (click)="changeCourse('Commerce')" (click)="checkRoute()">Commerce</a>
                            </li>
                                    <li routerLinkActive="active">
                                <a routerLink="/courses/physical" (click)="changeCourse('Physical Education, Health Education and Sports')" (click)="checkRoute()">Physical Education, Health Education and Sports</a>
                            </li>
                                    <li routerLinkActive="active">
                                <a routerLink="/courses/ugc" (click)="changeCourse('UGC Skill Based Course')" (click)="checkRoute()">UGC Skill Based Course </a>
                            </li>
                                    <li routerLinkActive="active">
                                <a routerLink="/courses/education" (click)="changeCourse('Education')" (click)="checkRoute()">Education</a>
                            </li>
                            <!-- <li routerLinkActive="active">
                                <a routerLink="/courses/add-on" (click)="changeCourse('Add On')" (click)="checkRoute()">Add On</a>
                            </li> -->
                            <!-- <li routerLinkActive="active">
                                <a routerLink="/courses/elective-courses" (click)="changeCourse('Elective Courses')" (click)="checkRoute()">Elective Courses</a>
                            </li> -->
                            <li routerLinkActive="active">
                        <a routerLink="/courses/sec" (click)="changeCourse('sec')" (click)="checkRoute()">Skill Enhancement Courses</a>
                    </li>
                            <li routerLinkActive="active">
                        <a routerLink="/courses/vac" (click)="changeCourse('vac')" (click)="checkRoute()">Value Added Courses</a>
                    </li>
                            <li routerLinkActive="active">
                        <a routerLink="/courses/aec" (click)="changeCourse('aec')" (click)="checkRoute()">Ability Enhancement Courses</a>
                    </li>
                            <li routerLinkActive="active">
                        <a routerLink="/courses/mdc" (click)="changeCourse('mdc')" (click)="checkRoute()">Multidisciplinary Courses</a>
                    </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/lesson-plan" (click)="changeCourse('Lesson Plan')" (click)="checkRoute()">Lesson Plan</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/courses/soft-skill" (click)="changeCourse('Soft Skill Language Lab')" (click)="checkRoute()">Soft Skill Language Lab</a>
                            </li>
                           
                                </ul>
                    </nav>
                    
                </div>
                <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
       
    </div>
</div>

