<div id="header">
    <div class="container-fluid">
        <div class="row top-bar">
            <div class="col-12">
                <div class="row">
                    <div class="col-4 col-sm-6 d-none d-xl-block"><i class="fas fa-university"></i> WELCOME TO GURU
                        NANAK KHALSA COLLEGE</div>
                    <div class="col-4 col-sm-4 d-none d-sm-block d-xl-none"><i class="fas fa-university"></i> WELCOME TO
                        GNKC</div>
                    <div class="col-12 col-sm-8 col-lg-6">
                        <div class="row d-flex justify-content-end align-items-center">
                            <div class=" mr-1 pr-1 loginData" (click)="LinkUrl('http://library.gnkc.ac.in')"
                                style="cursor: pointer;"><i class="fas fa-sign-in-alt"></i> Library Login</div>
                            <div class="vertical-bar rounded mr-1 mr-md-2"></div>
                            <div class=" mr-1 pr-1 loginData"
                                (click)="LinkUrl('https://panel.okiedokiepay.com/auth/GNKC')" style="cursor: pointer;">
                                <i class="fas fa-sign-in-alt"></i> LMS Login
                            </div>
                            <div class="vertical-bar rounded mr-1 mr-md-2"></div>
                            <div class=" mr-1 pr-1 d-none d-sm-block" style="cursor: pointer;"
                                (click)="LinkUrl('http://Campus.odpay.in')"><i class="fas fa-sign-in-alt"></i> ERP Login
                            </div>
                            <div class="vertical-bar rounded mr-1 mr-md-2 d-none d-sm-block"></div>
                            <div class=" mr-1 pr-1 loginData" style="cursor: pointer;" data-toggle="modal"
                                data-target="#paymentModal"><i class="bi bi-credit-card"></i> Online Payment</div>
                            <!-- <div class=" mr-1 pr-1 " style="cursor: pointer;" (click)="LinkUrl('https://panel.okiedokiepay.com/auth/GNKITM')"><i class="fas fa-sign-in-alt"></i> Student ERP Login</div> -->
                            <!-- (click)="LinkUrl('http://odpay.in/gnkc')" -->
                            <div class="vertical-bar rounded mr-1 mr-md-2"></div>
                            <div class="mr-md-4 mobileApplyNow loginData" style="cursor: pointer;" data-toggle="modal"
                                data-target="#applyModal"><i class="fas fa-check-double"></i> Apply Now</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="showcase">
        <div id="headerSlider" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#headerSlider" data-slide-to="0" class="active"></li>
                <li data-target="#headerSlider" data-slide-to="1"></li>
                <li data-target="#headerSlider" data-slide-to="2"></li>
                <li data-target="#headerSlider" data-slide-to="3"></li>
                <li data-target="#headerSlider" data-slide-to="4"></li>
                <!--<li data-target="#headerSlider" data-slide-to="5"></li> -->
                <!-- <li data-target="#headerSlider" data-slide-to="4"></li> -->
            </ol>
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[0]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100 admission" src="/assets/img/showcase/15.png" alt="program"
                        [ngStyle]="{visibility:isLoading[0]?'hidden':'visible'}" (load)="hideLoader(0)"
                        (error)="hideLoader(0)">
                    <div class="badge1 d-block d-lg-none">
                        <img src="/assets/img/showcase/ajadi.jpeg" alt="">
                    </div>
                    <div class="carousel-caption brochure d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[0]">
                        <h1
                            class="link-shadow w-100 d-flex justify-content-start animate__animated animate__backInLeft">
                            Where Dreams</h1>
                        <h1
                            class="link-shadow w-100 d-flex justify-content-start animate__animated animate__backInLeft">
                            Become Reality</h1>
                        <br>
                        <div class="card animate__animated animate__backInLeft">
                            <form class="h-100" #brochureForm="ngForm" (ngSubmit)="onSubmit(brochureForm)">
                                <div class="row h-100">
                                    <div class="col-6 brochure1">

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text border-0" id="basic-addon1"><i
                                                        class="bi bi-envelope"></i></span>
                                            </div>
                                            <input type="email" class="form-control border-0"
                                                placeholder="Enter Your Email" aria-label="Email"
                                                aria-describedby="basic-addon1" ngModel required email id="email"
                                                name="email">
                                        </div>
                                        <!-- <i class="bi bi-envelope"></i>
                            <span >Enter Your Email</span> -->
                                    </div>
                                    <div class="col-5 brochure2">

                                        <button class="btn btn-warning" type="submit">Get Brochure</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="carousel-item ">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[1]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/17.jpg" alt="program"
                        style="filter: brightness(1); " [ngStyle]="{visibility:isLoading[1]?'hidden':'visible'}"
                        (load)="hideLoader(1)" (error)="hideLoader(1)">
                    <div class="badge1 d-block d-lg-none">
                        <img src="/assets/img/showcase/ajadi.jpeg" alt="">
                    </div>
                    <div class="carousel-caption campus d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[1]">

                        <h1 class="link-shadow">Striving For Excellence</h1>
                        <button class="btn btn-warning " data-toggle="modal" data-target="#applyModal">Apply
                            today!</button>
                    </div>
                </div>

                <!-- <div class="carousel-item">
                <img class="d-block w-100" src="/assets/img/showcase/1.jpg" alt="program"> 
                <div class="carousel-caption d-none d-md-block animate__animated animate__slideInDown">
                    <h1>DJ NIGHT</h1>
                  </div>
              </div> -->
                <!-- <div class="carousel-item">
                <img class="d-block w-100" src="/assets/img/showcase/2.jpg" alt="Building"> 
                <div class="carousel-caption d-none d-md-block animate__animated animate__slideInDown">
                    <h1>CAMPUS</h1>
                  </div>
              </div> -->
                <div class="carousel-item">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[2]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/5.JPG" alt="Building"
                        style="filter: brightness(0.8);" [ngStyle]="{visibility:isLoading[2]?'hidden':'visible'}"
                        (load)="hideLoader(2)" (error)="hideLoader(2)">
                    <div class="badge1 d-block d-lg-none">
                        <img src="/assets/img/showcase/ajadi.jpeg" alt="">
                    </div>
                    <div class="carousel-caption campus staff d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[2]">
                        <h1 class="link-shadow">We Prepare Leaders For Tomorrow</h1>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[3]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/18.jpg" alt="Building"
                        style="filter: brightness(0.7);" [ngStyle]="{visibility:isLoading[3]?'hidden':'visible'}"
                        (load)="hideLoader(3)" (error)="hideLoader(3)">
                    <div class="badge1 d-block d-lg-none">
                        <img src="/assets/img/showcase/ajadi.jpeg" alt="">
                    </div>
                    <div class="carousel-caption campus d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[3]">
                        <button class="btn btn-warning ">#CampusLife</button>
                        <h1 class="link-shadow">The Future Awaits</h1>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[4]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/19.jpg" alt="Building"
                        style="filter: brightness(0.6);" [ngStyle]="{visibility:isLoading[4]?'hidden':'visible'}"
                        (load)="hideLoader(4)" (error)="hideLoader(4)">
                    <div class="badge1 d-block d-lg-none">
                        <img src="/assets/img/showcase/ajadi.jpeg" alt="">
                    </div>
                    <div class="carousel-caption campus d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[4]">
                        <button class="btn btn-warning ">#Life @ GNKC</button>
                        <h1 class="link-shadow">Success Starts In Campus</h1>
                    </div>

                </div>
            </div>
            <a class="carousel-control-prev" href="#headerSlider" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#headerSlider" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <div class="brand d-flex flex-row justify-content-start align-items-center">
        <img src="/assets/img/logo/3.png" alt="Logo" class="img-fluid rounded">
        <h4 id="group-name" class="d-block">GURU NANAK KHALSA COLLEGE <br> <span class="eminance">An Institution Of
                Eminence</span></h4>
        <!-- <h4 id="group-welcome" class="d-none" style="font-size: 24px;">An Institution Of Eminence</h4> -->
    </div>
    <div id="menu-bar">
        <nav class="navbar navbar-expand-xl">
            <button class="navbar-toggler" data-toggle="collapse" data-target="#menu">
                <span class="navbar-toggler-icon" style="color: var(--yellow);"><i class="fas fa-bars"></i></span>

            </button>
            <div class="collapse navbar-collapse" id="menu">
                <ul class="navbar-nav  text-center ">
                    <li class="nav-item" id="home" routerLinkActive="active">
                        <a routerLink="/" class="nav-link link-shadow" data-toggle="collapse"
                            data-target="#menu"><mat-icon aria-hidden="false"
                                aria-label="Example home icon">home</mat-icon></a>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">About Us</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/profile"> College Profile</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/governing" style="white-space: initial;"> Guru Nanak Khalsa
                                        College Managing Committee, Yamunanagar</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/messages"> Messages</a>

                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/committee"> Staff Committees</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/organogram"> Organogram</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/perspective"> Perspective Plan</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/rti"> RTI</a>
                                    <h6 class="dropdown-header my-2">Faculty</h6>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/faculty/teaching-staff"> Teaching Staff</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/about/staff"> Administrative Staff</a>
                                </div>

                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown" routerLinkActive="active">
                        <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Academics</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row megamenu">
                                <div class="col-12 ">

                                    <div class="row">
                                        <div class="col-lg-4 ">

                                            <div class=" ">
                                                <h6 class="dropdown-header" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/course">Courses</h6>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/arts" (click)="setUrl('Humanities')">
                                                    Humanities</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/science" (click)="setUrl('Science')">
                                                    Science</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/commerce" (click)="setUrl('Commerce')">
                                                    Commerce</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/physical"
                                                    (click)="setUrl('Physical Education')"> Physical Education</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/ugc"
                                                    (click)="setUrl('UGC Skills Based Course')"> UGC Skills Based
                                                    Course</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/education" (click)="setUrl('Education')">
                                                    Education</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/sec" (click)="setUrl('sec')">
                                                    Skill Enhancement Courses</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/vac" (click)="setUrl('vac')">
                                                    Value Added Courses</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/aec" (click)="setUrl('aec')">
                                                    Ability Enhancement Courses</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/mdc" (click)="setUrl('mdc')">
                                                    Multidisciplinary Courses</a>
                                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/add-on" (click)="setUrl('Add On')"> Add On
                                                    Courses</a> -->
                                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/elective-courses"
                                                    (click)="setUrl('Elective Courses')"> Elective Courses</a> -->
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/lesson-plan" (click)="setUrl('Lesson Plan')">
                                                    Lesson Plan</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/courses/soft-skill"
                                                    (click)="setUrl('Soft Skill Language Lab')">
                                                    Soft Skill Language Lab</a>
                                            </div>

                                        </div>

                                        <div class="col-12 col-lg-4 ">

                                            <div class=" ">
                                                <h6 class="dropdown-header">Research</h6>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/research-centre"
                                                    (click)="setUrl('Research Centre')"> Research Centre</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/Seminar-conference"
                                                    (click)="setUrl('Seminar/ Conferences')"> Seminars/Conferences</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/workshops" (click)="setUrl('Workshops')">
                                                    Workshops</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/guest-lecture"
                                                    (click)="setUrl('Guest Lecture')"> Guest Lectures</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/fdps" (click)="setUrl('FDPs')"> FDPs</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/result" (click)="setUrl('Results')">
                                                    Results</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                    routerLink="/research/pos-cos" (click)="setUrl('POs & COs')">
                                                    PO's & CO's</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                    routerLink="/research/mou" (click)="setUrl('Functional MOUs')">
                                                    Functional MOU's</a>
                                                <h6 class="dropdown-header">Academic Calender</h6>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/calender/2022-23" (click)="setUrl('2022-23')">
                                                    2022-23</a>

                                            </div>

                                        </div>
                                        <div class="col-12 col-lg-4 ">

                                            <div class="">
                                                <h6 class="dropdown-header">Projects</h6>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/major-research-project"
                                                    (click)="setUrl('Major Research Projects')"> Major Research
                                                    Projects</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/minor-research-project"
                                                    (click)="setUrl('Minor Research Projects')"> Minor Research
                                                    Projects</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/student-project"
                                                    (click)="setUrl('Student Projects')"> Student's Projects</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/collaborative"
                                                    (click)="setUrl('Collaborative Activities')"> Collaborative
                                                    Activities</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/publication" (click)="setUrl('Publications')">
                                                    Publications</a>
                                                <h6 class="dropdown-header mt-2">E-Content</h6>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/e-contents"> E-Content</a>
                                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    (click)="LinkUrl('https://khalsa.odlms.in')"> E-Content</a> -->
                                                <h6 class="dropdown-header mt-2">Scholarship</h6>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/research/scholarship" (click)="setUrl('Scholarship')">
                                                    Scholarship</a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown" routerLinkActive="active">
                        <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Activities</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row megamenu">
                                <div class="col-12 ">
                                    <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="dashes">
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/ncc" (click)="setUrl('NCC')"> NCC</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/nss" (click)="setUrl('NSS')"> NSS</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/sports" (click)="setUrl('Sports')">
                                                    Sports</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/Women-cell" (click)="setUrl('Women Cell')">
                                                    Women Cell</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/cultural"
                                                    (click)="setUrl('Cultural Activities')"> Cultural Activities</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/legal-literacy-cell"
                                                    (click)="setUrl('Legal Literacy Cell')"> Legal Literacy Cell</a>
                                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/cross-cutting"
                                                    (click)="setUrl('Cross Cutting')">
                                                    Cross Cutting</a> -->
                                                <!-- <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/social"
                                                    (click)="setUrl('Social Outreach Activities')"> Social Outreach
                                                    Activities</a> -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="dashes">
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/youth-red-cross"
                                                    (click)="setUrl('Youth Red Cross')"> Youth Red Cross</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/eco-club" (click)="setUrl('ECO Club')"> ECO
                                                    Club</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/adventure-club"
                                                    (click)="setUrl('Adventure Club')"> Adventure Club</a>
                                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/placement-cell"
                                                    (click)="setUrl('Placement Cell')"> Placement Cell</a> -->
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/community-services"
                                                    (click)="setUrl('Community Services')"> Community Services</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/gender" (click)="setUrl('Gender Equality')">
                                                    Gender Equality</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/activities/VISMAD" (click)="setUrl('VISMAD')">
                                                    VISMAD</a>

                                            </div>

                                        </div>
                                        <!-- <div class="col-12 col-lg-4 ">
                                            <div class="dashes">
                                                
                                            </div>
                                        </div> -->
                                    </div>
                                </div>


                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown" routerLinkActive="active">
                        <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Infrastructure</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row megamenu">
                                <div class="col-12 ">
                                    <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                    <div class="row">
                                        <div class="col-12 col-lg-4">
                                            <div class="dashes">
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Girls-Hostel"
                                                    (click)="setUrl('Girls Hostel')"> Girls Hostel</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Gymnasium-Hall"
                                                    (click)="setUrl('Gymnasium Hall')"> Gymnasium Hall</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Gurudwara-Sahib"
                                                    (click)="setUrl('Gurudwara Sahib')"> Gurudwara Sahib</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Labs" (click)="setUrl('Labs')"> Labs</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Language-Lab"
                                                    (click)="setUrl('Language Lab')"> Language Lab</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/ICT-Lab" (click)="setUrl('ICT Lab')">
                                                    ICT Lab</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Canteen" (click)="setUrl('Canteen')">
                                                    Canteen</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Play-Fields"
                                                    (click)="setUrl('Play Fields')"> Play Fields</a>
                                            </div>

                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <div class="dashes">
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Girls-Common-Room"
                                                    (click)="setUrl('Girls Common Room')"> Girls Common Room</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/Boys-Common-Room"
                                                    (click)="setUrl('Boys Common Room')"> Boys Common Room</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/virtual"
                                                    (click)="setUrl('Virtual classroom')"> Virtual classroom</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/recording"
                                                    (click)="setUrl('Lecture Recording Studio')"> Lecture Recording
                                                    Studio</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/audio"
                                                    (click)="setUrl('Audio Visual Hall')"> Audio Visual Hall</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/health"
                                                    (click)="setUrl('Health & Fitness Centre')"> Health & Fitness
                                                    Centre</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/khelo"
                                                    (click)="setUrl('Khelo India Center')"> Khelo India Center</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/competitive"
                                                    (click)="setUrl('Competitive Exam Guidance  Corner')"> Competitive
                                                    Exam Guidance Corner</a>
                                            </div>

                                        </div>
                                        <div class="col-12 col-lg-4 ">
                                            <div class="dashes">

                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/ethics"
                                                    (click)="setUrl('Corner for Ethics')"> Corner for Ethics</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/seminar-hall"
                                                    (click)="setUrl('Seminar Hall')"> Seminar Hall</a>
                                                <h6 class="dropdown-header">Library</h6>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/library/about"
                                                    (click)="setUrl('About Library')"> About Library</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/library/Subscribed"
                                                    (click)="setUrl('Subscribed e-Resources')"> Subscribed
                                                    e-Resources</a>
                                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/library/open-access"
                                                    (click)="setUrl('Open Access e-Resources')"> Open Access
                                                    e-Resources</a>
                                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                    routerLink="/infrastructure/digital"
                                                    (click)="setUrl('Digital Library')"> Digital Library</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- <li class="nav-item dropdown" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Research</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row megamenu">
                            <div class="col-12 ">
                                
                                <div class="row">
                                    <div class="col-12 col-lg-4">
                                        <div class="dashes">
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/research-centre"  (click)="setUrl('Research Centre')"> Research Centre</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/major-research-project" (click)="setUrl('Major Research Projects')"> Major Research Projects</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/minor-research-project" (click)="setUrl('Minor Research Projects')"> Minor Research Projects</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/student-project" (click)="setUrl('Student Projects')"> Student's Projects</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/publication" (click)="setUrl('Publications')"> Publications</a>
                                        </div>
                                       
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="dashes">
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/Seminar-conference" (click)="setUrl('Seminar/ Conferences')"> Seminar/ Conferences</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/workshops" (click)="setUrl('Workshops')"> Workshops</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/guest-lecture" (click)="setUrl('Guest Lecture')"> Guest Lectures</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                            routerLink="/research/fdps" (click)="setUrl('FDPs')"> FDPs</a>
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-4 d-none d-lg-block"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> -->
                    <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                        <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">IQAC</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/nirf"> NIRF</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/institutional"> Institutional Distinctiveness</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/development"> Institutional Development Plan</a>
                                    <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                    routerLink="/not-found"> SSR</a> -->

                                    <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                            routerLink="/wp-content/uploads/2020/02" (click)="setUrl('AQAR Links')"> AQAR Links</a> -->
                                    <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                    routerLink="/about/manager-message"> NAAC</a> -->
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                        routerLink="/iqac/curriculum"> Curriculum Feedback</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/best-practices"> Best Practices</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/student-satisfaction-survey"
                                        (click)="setUrl('Student Satisfaction Survey')"> Student Satisfaction Survey</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/meetings" (click)="setUrl('Meetings')"> Meetings</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/human-value" style="white-space: initial;"
                                        (click)="setUrl('Human Values and  Professional Ethics')"> Human Values and
                                        Professional Ethics</a>
                                    <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/iqac/aqar"> AQAR</a> -->
                                </div>


                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                        <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">NAAC</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                        routerLink="/naac/iiqa"> IIQA</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/naac/ssr"> SSR</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/naac/dvv"> DVV</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/naac/aqar"> AQAR</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/naac/team-report"> NAAC Peer Team Report</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                        <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Student Corner</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu"
                                        routerLink="/student-corner/prospectus"> Prospectus</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/gallery"> Gallery & Campus Life</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu" routerLink="/">
                                        Beyond Academics</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        (click)="LinkUrl('https://docs.google.com/forms/d/e/1FAIpQLSc_TL614DWSLBRVTqCcAztfsfI941iaOHOBNh4WNqq5yRmFow/viewform?usp=sf_link')">
                                        Campus Wi-Fi Form</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/student-corner/grievance" (click)="setUrl('Grievance')">
                                        Grievance</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                        routerLink="/student-corner/anti-ragging" (click)="setUrl('Anti Ragging')">
                                        Anti Ragging</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/student-corner/student-feedback"
                                        (click)="setUrl('Student Feedback')">Student
                                        Feedback</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                        (click)="LinkUrl('https://data.odpay.in/odforms/form/KhalsaAlumniClub/formperma/hOy23rsd-kizC--o3YucyFO0MF1ZOsQSaHnpwgddxc4')">
                                        Alumni Registration</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                        routerLink="/student-corner/important-links"
                                        (click)="setUrl('Important Links')"> Important Links</a>
                                    <!-- <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                        routerLink="/student-corner/co-po"> CO - PO</a> -->
                                </div>


                            </div>
                        </div>
                    </li>
                    <!-- <li class="nav-item dropdown" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Students Corner</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row megamenu">
                            <div class="col-12 ">
                               
                                <div class="row">
                                    <div class="col-12 col-lg-4 mt-2">
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/student-corner/anti-ragging" (click)="setUrl('Anti Ragging Cell')"> Anti Ragging Cell</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/student-corner/prospectus" (click)="setUrl('Prospectus')"> Prospectus</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/chairman-message"> Academic Calender</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/not-found" (click)="setUrl('Whom To Consult')"> Whom To Consult</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/principal"> Forms</a>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Attendence</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/not-found"> Results</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/chairman-message"> Internal Assessment</a>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <h6 class="dropdown-header mt-2">Syllabus</h6>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Arts</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/not-found"> Commerce</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/chairman-message"> Science</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/manager-message"> Post Graduate Courses</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/principal"> Sports</a>
                                        <h6 class="dropdown-header mt-2">Student's Form</h6>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Feedback Form</a>

                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <h6 class="dropdown-header mt-2">Time Table</h6>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Art</a>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Commerce</a>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Science</a>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Sports</a>
                                        <h6 class="dropdown-header mt-2">Scholarships</h6>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> College Scholarship</a>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu" 
                                        routerLink="/about/intro"> Jamna Auto <br> industries Scholarships</a>
                                    </div>
                                   
                                </div>
                               
                            </div>
                           
                           
                        </div>
                    </div>
                </li> -->
                    <li class="nav-item" id="smallDrop" routerLinkActive="active">
                        <a routerLink="/menu-detail/career" class="nav-link link-shadow" data-toggle="collapse"
                            data-target="#menu">Career</a>
                    </li>
                    <li class="nav-item bg-warning mx-auto mx-lg-0" id="apply" style="width: 150px;border-radius: 5px;">
                        <a class="nav-link text-dark font-weight-bold" style="line-height: 30px;" data-toggle="modal"
                            data-target="#applyModal"> Apply Now </a>
                    </li>

                </ul>
            </div>
        </nav>
    </div>

    <div id="types" class="d-none d-lg-block animate__animated animate__fadeIn" *ngIf="showShowcase">
        <div class="row text-center">
            <div class="col-3 type4">
                <p>NAAC Accredited</p>
                <div id="work" class="">
                    <img src="/assets/img/showcase/naac.png" alt="Collabration" class="img-fluid rounded">
                </div>

            </div>
            <div class="col-3 type3" (click)="scrollTo('unique')">
                <p class="mb-0">25 Times Overall</p>
                <p class="mt-0">Sports Champions</p>
                <div id="work">
                    <img src="/assets/svg/header/sport.svg" alt="Program" style="height: 95px;" class="img-fluid">
                </div>

            </div>
            <div class="col-3 type2" (click)="scrollTo('scholarship')">
                <!-- <p class="mb-0">Lush Green</p>
                <p class="mt-0">Eco-friendly Campus</p> -->
                <div id="tech">
                    <img src="/assets/img/showcase/ajadi.jpeg" alt="Ajadi" style="height: 180px;" class="img-fluid">
                    <!-- <img src="/assets/svg/header/green.svg" alt="NCC" style="height: 95px;" class="img-fluid"> -->
                </div>

            </div>
            <div class="col-3 type1" (click)="scrollTo('scholarship')">
                <p class="mb-1">500+ Scholarships</p>

                <div id="work">
                    <i class="fas fa-award"></i>
                </div>



            </div>



        </div>
    </div>

    <div id="typeshover" class="d-none animate__animated animate__fadeIn" *ngIf="showShowcase">
        <div class="row text-center">
            <div class="col-3 type4">
                <p class="mb-0">50+ Ph.D. </p>
                <p class="mt-0">Holder Faculties</p>
                <div id="work">
                    <img src="/assets/svg/header/12.svg" alt="Collabration" style="height: 95px;" class="img-fluid">
                </div>

            </div>
            <div class="col-3 type3" (click)="scrollTo('unique')">
                <p>Khelo India Center</p>
                <div id="work">
                    <img src="/assets/img/types/2.png" alt="Program" class="img-fluid rounded-pill">
                </div>

            </div>
            <div class="col-3 type2" (click)="scrollTo('scholarship')">
                <!-- <p class="mb-0">Competitive Exams</p>
                <p class="mt-0">Prep Center</p> -->
                <div id="tech">
                    <img src="/assets/img/showcase/ajadi.jpeg" alt="Ajadi" style="height: 180px;" class="img-fluid">
                    <!-- <img src="/assets/svg/header/center.svg" alt="NCC" style="height: 95px;" class="img-fluid"> -->
                </div>

            </div>
            <div class="col-3 type1" (click)="scrollTo('scholarship')">
                <p class="mb-0">Consistent Social</p>
                <p class="mt-0">Outreach Programs</p>
                <div id="work">
                    <img src="/assets/svg/header/social.svg" alt="Collabration" style="height: 95px;" class="img-fluid">
                </div>



            </div>



        </div>
    </div>

</div>

<div id="menu-header1">
    <nav class="navbar navbar-expand-xl">
        <a class="navbar-brand text-center ml-5" href="#"><img src="/assets/img/logo/3.png" alt="Logo"
                class="img-fluid"></a>
        <button class="navbar-toggler" data-toggle="collapse" data-target="#menu1">
            <span class="navbar-toggler-icon" style="color: var(--white);"><i class="fas fa-bars"></i></span>

        </button>
        <div class="collapse navbar-collapse" id="menu1">
            <ul class="navbar-nav  text-center ">
                <li class="nav-item" id="home" routerLinkActive="active">
                    <a routerLink="/" class="nav-link link-shadow" data-toggle="collapse" data-target="#menu1"><mat-icon
                            aria-hidden="false" aria-label="Example home icon">home</mat-icon></a>
                </li>
                <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                    <a href="#" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">About Us</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row">
                            <div class="col-12 ">
                                <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/profile"> College Profile</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/governing" style="white-space: initial;"> Guru Nanak Khalsa
                                    College Managing Committee, Yamunanagar</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/messages"> Messages</a>

                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/committee"> Staff Committees</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/organogram"> Organogram</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                    routerLink="/about/perspective"> Perspective Plan</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/rti"> RTI</a>
                                <h6 class="dropdown-header my-2">Faculty</h6>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/faculty/teaching-staff"> Teaching Staff</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/about/staff"> Administrative Staff</a>
                            </div>


                        </div>

                    </div>
                </li>
                <li class="nav-item dropdown" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Academics</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row megamenu">
                            <div class="col-12 ">

                                <div class="row">
                                    <div class="col-lg-4 ">

                                        <div class="">
                                            <h6 class="dropdown-header" data-toggle="collapse" data-target="#menu"
                                                routerLink="/course">Courses</h6>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/arts" (click)="setUrl('Humanities')">
                                                Humanities</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/science" (click)="setUrl('Science')"> Science</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/commerce" (click)="setUrl('Commerce')">
                                                Commerce</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/physical" (click)="setUrl('Physical Education')">
                                                Physical Education</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/ugc" (click)="setUrl('UGC Skills Based Course')">
                                                UGC Skills Based Course</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/education" (click)="setUrl('Education')">
                                                Education</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/sec" (click)="setUrl('sec')">
                                                Skill Enhancement Courses</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/vac" (click)="setUrl('vac')">
                                                Value Added Courses</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/aec" (click)="setUrl('aec')">
                                                Ability Enhancement Courses</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/mdc" (click)="setUrl('mdc')">
                                                Multidisciplinary Courses</a>
                                            <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/add-on" (click)="setUrl('Add On')"> Add On
                                                Courses</a> -->
                                            <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/elective-courses"
                                                (click)="setUrl('Elective Courses')"> Elective Courses</a> -->
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/lesson-plan" (click)="setUrl('Lesson Plan')">
                                                Lesson Plan</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/courses/soft-skill"
                                                (click)="setUrl('Soft Skill Language Lab')">
                                                Soft Skill Language Lab</a>
                                        </div>

                                    </div>

                                    <div class="col-12 col-lg-4">

                                        <div class="">
                                            <h6 class="dropdown-header">Research</h6>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/research-centre"
                                                (click)="setUrl('Research Centre')"> Research Centre</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/Seminar-conference"
                                                (click)="setUrl('Seminar/ Conferences')"> Seminars/Conferences</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/workshops" (click)="setUrl('Workshops')">
                                                Workshops</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/guest-lecture" (click)="setUrl('Guest Lecture')">
                                                Guest Lectures</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/fdps" (click)="setUrl('FDPs')"> FDPs</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/result" (click)="setUrl('Results')"> Results</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/pos-cos" (click)="setUrl('POs & COs')">
                                                PO's & CO's</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/mou" (click)="setUrl('Functional MOUs')">
                                                Functional MOU's</a>
                                            <h6 class="dropdown-header">Academic Calender</h6>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/calender/2022-23" (click)="setUrl('2022-23')"> 2022-23</a>

                                        </div>

                                    </div>
                                    <div class="col-12 col-lg-4">

                                        <div class="">
                                            <h6 class="dropdown-header">Projects</h6>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/major-research-project"
                                                (click)="setUrl('Major Research Projects')"> Major Research Projects</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/minor-research-project"
                                                (click)="setUrl('Minor Research Projects')"> Minor Research Projects</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/student-project"
                                                (click)="setUrl('Student Projects')"> Student's Projects</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/collaborative"
                                                (click)="setUrl('Collaborative Activities')"> Collaborative
                                                Activities</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/publication" (click)="setUrl('Publications')">
                                                Publications</a>
                                            <h6 class="dropdown-header mt-2">E-Content</h6>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/e-contents"> E-Content</a>
                                            <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                (click)="LinkUrl('https://khalsa.odlms.in')"> E-Content</a> -->
                                            <h6 class="dropdown-header mt-2">Scholarship</h6>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/research/scholarship" (click)="setUrl('Scholarship')">
                                                Scholarship</a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                >Courses</a>
                <div class="dropdown-menu " aria-labelledby="menu">
                    <div class="row">
                        <div class="col-12 ">
                            
                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/arts" (click)="setUrl('Arts')"> Arts</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/science" (click)="setUrl('Science')"> Science</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/commerce" (click)="setUrl('Commerce')"> Commerce</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/physical" (click)="setUrl('Physical Education')"> Physical Education</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/ugc" (click)="setUrl('UGC Skills')"> UGC Skills</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/education" (click)="setUrl('Education')"> Education</a>
                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/courses/add-on" (click)="setUrl('Add On')"> Add On</a>
                        </div>
                       
                    </div>
                </div>
            </li> -->
                <!-- <li class="nav-item dropdown" routerLinkActive="active">
                <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Faculty</a>
                <div class="dropdown-menu " aria-labelledby="menu">
                    <div class="row megamenu">
                        <div class="col-12 ">
                           
                            <div class="row">
                                <div class="col-12 col-lg-4">
                                    <h6 class="dropdown-header mt-2">Humanities</h6>
                                   
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/english" (click)="setUrl('Department Of English')"> Department Of English</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/economics" (click)="setUrl('Department Of Economics')"> Department Of Economics</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/education-bed" (click)="setUrl('Department of Education (B.Ed.')"> Department of Education (B.Ed.)</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/geography" (click)="setUrl('Department Of Geography')"> Department Of Geography</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/hindi" (click)="setUrl('Department Of Hindi')"> Department Of Hindi</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/history" (click)="setUrl('Department Of History')"> Department Of History</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/political-science" (click)="setUrl('Department Of Political Science')"> Department Of Political Science</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/education" (click)="setUrl('Department of Physical Education (B.P.Ed.)')"> Department of Physical Education (B.P.Ed.)</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/punjabi" (click)="setUrl('Department Of Punjabi')"> Department Of Punjabi</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/social-work" (click)="setUrl('Department Of Social Work')"> Department Of Social Work</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/mass-communication" (click)="setUrl('Department Of Mass Communication')"> Department Of Mass <br> Communication</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/sanskirt" (click)="setUrl('Department of Sanskrit')"> Department of Sanskrit</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/humanities/music" (click)="setUrl('Department of Music')"> Department of Music</a>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <h6 class="dropdown-header mt-2">Science</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/botony" (click)="setUrl('Department Of Botany')"> Department Of Botany</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/mathematics" (click)="setUrl('Department Of Mathematics')"> Department Of Mathematics</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/physics" (click)="setUrl('Department Of Physics')"> Department Of Physics</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/computer-science" (click)="setUrl('Department Of Computer Science')"> Department Of Computer Science</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/zoology" (click)="setUrl('Department Of Zoology')"> Department Of Zoology</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/chemistry" (click)="setUrl('Department Of Chemistry')"> Department Of Chemistry</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/industrial-chemistry" (click)="setUrl('Department Of Industrial Chemistry')"> Department Of Industrial <br> Chemistry</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/microbiology" (click)="setUrl('Department Of Microbiology')"> Department Of Microbiology</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/science/biotechnology" (click)="setUrl('Department Of Biotechnology')"> Department Of Biotechnology</a>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <h6 class="dropdown-header mt-2">Commerce</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/commerce/commerce&management" (click)="setUrl('Department Of Commerce & Management')"> Department Of Commerce & <br> Management</a>
                                    <h6 class="dropdown-header mt-2">Sports Sciences</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/sports-sciences/health-education" (click)="setUrl('Department Of Physical Education, Health Education & Sports Sciences')"> Department Of Physical Education, <br> Health Education & <br> Sports Sciences</a>
                                    <h6 class="dropdown-header mt-2">Community College</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/faculty/community" (click)="setUrl('Department Of Community College')"> Department Of Community College</a>
                                </div>
                               
                            </div>
                           
                        </div>
                       
                       
                    </div>
                </div>
            </li> -->
                <li class="nav-item dropdown" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Activities</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row megamenu">
                            <div class="col-12 ">
                                <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="dashes">
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/ncc" (click)="setUrl('NCC')"> NCC</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/nss" (click)="setUrl('NSS')"> NSS</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/sports" (click)="setUrl('Sports')"> Sports</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/Women-cell" (click)="setUrl('Women Cell')">
                                                Women Cell</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/cultural"
                                                (click)="setUrl('Cultural Activities')"> Cultural Activities</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/legal-literacy-cell"
                                                (click)="setUrl('Legal Literacy Cell')"> Legal Literacy Cell</a>
                                            <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/cross-cutting" (click)="setUrl('Cross Cutting')">
                                                Cross Cutting</a> -->
                                            <!-- <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/social"
                                                (click)="setUrl('Social Outreach Activities')"> Social Outreach
                                                Activities</a> -->
                                        </div>


                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="dashes">
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/youth-red-cross"
                                                (click)="setUrl('Youth Red Cross')"> Youth Red Cross</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/eco-club" (click)="setUrl('ECO Club')"> ECO
                                                Club</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/adventure-club"
                                                (click)="setUrl('Adventure Club')"> Adventure Club</a>
                                            <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu"
                                                routerLink="/activities/placement-cell"
                                                (click)="setUrl('Placement Cell')"> Placement Cell</a> -->
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/community-services"
                                                (click)="setUrl('Community Services')"> Community Services</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/gender" (click)="setUrl('Gender Equality')">
                                                Gender Equality</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/activities/VISMAD" (click)="setUrl('VISMAD')"> VISMAD</a>

                                        </div>

                                    </div>
                                    <!-- <div class="col-lg-4 d-none d-lg-block">
                                       
                                    </div> -->
                                </div>
                            </div>


                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Infrastructure</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row megamenu">
                            <div class="col-12 ">
                                <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                <div class="row">
                                    <div class="col-12 col-lg-4">
                                        <div class="dashes">
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Girls-Hostel"
                                                (click)="setUrl('Girls Hostel')"> Girls Hostel</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Gymnasium-Hall"
                                                (click)="setUrl('Gymnasium Hall')"> Gymnasium Hall</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Gurudwara-Sahib"
                                                (click)="setUrl('Gurudwara Sahib')"> Gurudwara Sahib</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Labs" (click)="setUrl('Labs')"> Labs</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Language-Lab"
                                                (click)="setUrl('Language Lab')"> Language Lab</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/ICT-Lab" (click)="setUrl('ICT Lab')"> ICT
                                                Lab</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Canteen" (click)="setUrl('Canteen')">
                                                Canteen</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Play-Fields"
                                                (click)="setUrl('Play Fields')"> Play Fields</a>
                                        </div>

                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <div class="dashes">
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Girls-Common-Room"
                                                (click)="setUrl('Girls Common Room')"> Girls Common Room</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/Boys-Common-Room"
                                                (click)="setUrl('Boys Common Room')"> Boys Common Room</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/virtual"
                                                (click)="setUrl('Virtual classroom')"> Virtual classroom</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/recording"
                                                (click)="setUrl('Lecture Recording Studio')"> Lecture Recording
                                                Studio</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/audio"
                                                (click)="setUrl('Audio Visual Hall')"> Audio Visual Hall</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/health"
                                                (click)="setUrl('Health & Fitness Centre')"> Health & Fitness Centre</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/khelo"
                                                (click)="setUrl('Khelo India Center')"> Khelo India Center</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/competitive"
                                                (click)="setUrl('Competitive Exam Guidance  Corner')"> Competitive Exam
                                                Guidance Corner</a>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-4 ">
                                        <div class="dashes">

                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/ethics"
                                                (click)="setUrl('Corner for Ethics')"> Corner for Ethics</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/seminar-hall"
                                                (click)="setUrl('Seminar Hall')"> Seminar Hall</a>
                                            <h6 class="dropdown-header">Library</h6>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/library/about"
                                                (click)="setUrl('About Library')"> About Library</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/library/Subscribed"
                                                (click)="setUrl('Subscribed e-Resources')"> Subscribed e-Resources</a>
                                            <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/library/open-access"
                                                (click)="setUrl('Open Access e-Resources')"> Open Access e-Resources</a>
                                            <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                                routerLink="/infrastructure/digital"
                                                (click)="setUrl('Digital Library')"> Digital Library</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- <li class="nav-item dropdown" routerLinkActive="active">
                <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Research</a>
                <div class="dropdown-menu " aria-labelledby="menu">
                    <div class="row megamenu">
                        <div class="col-12 ">
                            
                            <div class="row">
                                <div class="col-12 col-lg-4">
                                    <div class="dashes">
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/research-centre"  (click)="setUrl('Research Centre')"> Research Centre</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/major-research-project" (click)="setUrl('Major Research Projects')"> Major Research Projects</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/minor-research-project" (click)="setUrl('Minor Research Projects')"> Minor Research Projects</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/student-project" (click)="setUrl('Student Projects')"> Student's Projects</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/publication" (click)="setUrl('Publications')"> Publications</a>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="dashes">
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/Seminar-conference" (click)="setUrl('Seminar/ Conferences')"> Seminar/ Conferences</a>
                                        <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/workshops" (click)="setUrl('Workshops')"> Workshops</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/guest-lecture" (click)="setUrl('Guest Lecture')"> Guest Lectures</a>
                                        <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                        routerLink="/research/fdps" (click)="setUrl('FDPs')"> FDPs</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 d-none d-lg-block"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li> -->
                <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu1" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">IQAC</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row">
                            <div class="col-12 ">
                                <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/nirf"> NIRF</a>
                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/institutional"> Institutional Distinctiveness</a>
                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/development"> Institutional Development Plan</a>
                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                routerLink="/not-found"> SSR</a> -->

                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                            routerLink="/wp-content/uploads/2020/02" (click)="setUrl('AQAR Links')"> AQAR Links</a> -->
                                <!-- <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                routerLink="/about/manager-message"> NAAC</a> -->
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/curriculum"> Curriculum Feedback</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/best-practices"> Best Practices</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/student-satisfaction-survey"
                                    (click)="setUrl('Student Satisfaction Survey')"> Student Satisfaction Survey</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/meetings" (click)="setUrl('Meetings')"> Meetings</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/iqac/human-value" style="white-space: initial;"
                                    (click)="setUrl('Human Values and  Professional Ethics')"> Human Values and
                                    Professional Ethics</a>
                            </div>


                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu1" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">NAAC</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row">
                            <div class="col-12 ">
                                <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/naac/iiqa"> IIQA</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/naac/ssr"> SSR</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/naac/dvv"> DVV</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/naac/aqar"> AQAR</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/naac/team-report"> NAAC Peer Team Report</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown" id="smallDrop" routerLinkActive="active">
                    <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Student Corner</a>
                    <div class="dropdown-menu " aria-labelledby="menu">
                        <div class="row">
                            <div class="col-12 ">
                                <!-- <h6 class="dropdown-header">GNKC</h6> -->
                                <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/student-corner/prospectus"> Prospectus</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/gallery"> Gallery & Campus Life</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" routerLink="/">
                                    Beyond Academics</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" routerLink="/">
                                    Campus Wi-Fi Form</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/student-corner/grievance" (click)="setUrl('Grievance')">
                                    Grievance</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/student-corner/anti-ragging" (click)="setUrl('Anti Ragging')">
                                    Anti Ragging</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/menu-detail/contact-us" (click)="setUrl('Contact Us')">Student
                                    Feedback</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    (click)="LinkUrl('https://data.odpay.in/odforms/form/KhalsaAlumniClub/formperma/hOy23rsd-kizC--o3YucyFO0MF1ZOsQSaHnpwgddxc4')">
                                    Alumni Registration</a>
                                <a class="dropdown-item" data-toggle="collapse" data-target="#menu1"
                                    routerLink="/student-corner/important-links" (click)="setUrl('Important Links')">
                                    Important Links</a>
                                <!-- <a class="dropdown-item " data-toggle="collapse" data-target="#menu1"
                                    routerLink="/student-corner/co-po"> CO - PO</a> -->
                            </div>


                        </div>
                    </div>
                </li>
                <!-- <li class="nav-item dropdown" routerLinkActive="active">
                <a href="" class="nav-link link-shadow dropdown-toggle" id="#menu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Students Corner</a>
                <div class="dropdown-menu " aria-labelledby="menu">
                    <div class="row megamenu">
                        <div class="col-12 ">
                        
                            <div class="row">
                                <div class="col-12 col-lg-4 mt-2">
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/student-corner/anti-ragging" (click)="setUrl('Anti Ragging Cell')"> Anti Ragging Cell</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/student-corner/prospectus" (click)="setUrl('Prospectus')"> Prospectus</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/chairman-message"> Academic Calender</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/not-found"> Whom To Consult</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/principal"> Forms</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Attendence</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/not-found"> Results</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/chairman-message"> Internal Assessment</a>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <h6 class="dropdown-header mt-2">Syllabus</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Arts</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/not-found"> Commerce</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/chairman-message"> Science</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/manager-message"> Post Graduate Courses</a>
                                    <a class="dropdown-item" data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/principal"> Sports</a>
                                    <h6 class="dropdown-header mt-2">Student's Form</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Feedback Form</a>

                                </div>
                                <div class="col-12 col-lg-4">
                                    <h6 class="dropdown-header mt-2">Time Table</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Art</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Commerce</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Science</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Sports</a>
                                    <h6 class="dropdown-header mt-2">Scholarships</h6>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> College Scholarship</a>
                                    <a class="dropdown-item " data-toggle="collapse" data-target="#menu1" 
                                    routerLink="/about/intro"> Jamna Auto <br> industries Scholarships</a>
                                </div>
                               
                            </div>
                           
                        </div>
                       
                       
                    </div>
                </div>
            </li> -->
                <li class="nav-item" id="home" routerLinkActive="active">
                    <a routerLink="/menu-detail/career" class="nav-link link-shadow" data-toggle="collapse"
                        data-target="#menu1">Career</a>
                </li>
                <li class="nav-item" style="cursor: pointer;">
                    <a class="nav-link font-weight-bold" data-toggle="modal" data-target="#applyModal"> Apply Now </a>
                </li>

            </ul>
        </div>
    </nav>
</div>

<div id="side-quick-links">
    <ul>
        <li class="call">
            <div class="content">
                <a href="#" class="icons"><i class="fa fa-phone" aria-hidden="true"></i></a>
                <p><a href="tel:01732-221675">01732-221675</a></p>
            </div>
        </li>

        <li class="marker">
            <div class="content">
                <a href="javascript:void(0)" class="icons" rel="nofollow"><i class="fa fa-globe"
                        aria-hidden="true"></i></a>
                <p><a class="sociable" href="https://facebook.com/gnkcynrofficial" target="_blank"><i
                            class="fa fa-facebook" aria-hidden="true"></i></a>
                    <a class="sociable" href="https://twitter.com/gnkcynrofficial" target="_blank"><i
                            class="fa fa-twitter" aria-hidden="true"></i></a>
                    <a class="sociable" href="https://youtube.com/c/GuruNanakKhalsaCollegeYamunanagar"
                        target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                    <a class="sociable" href="https://instagram.com/gnkcynrofficial" target="_blank"><i
                            class="fa fa-instagram" aria-hidden="true"></i></a>
                </p>
            </div>
        </li>
        <li class="vertualtour">
            <div class="content">
                <a href="#" class="icons" target="_blank"><i class="fa fa fa-binoculars" aria-hidden="true"></i></a>
                <p> <a href="#" target="_blank">Virtual Tour</a></p>

            </div>
        </li>
        <li class="watsapp">
            <div class="content">
                <a href="javascript:void(0)" class="icons" rel="nofollow"><i class="far fa-comments"></i></a>

                <p><a href="#">Feedback</a></p>


            </div>
        </li>


    </ul>

</div>
<!-- <div class="muck-up">
    <div class="filter-btn">
      <a id="one" class="test" href="tel:7999320000" data-toggle="tooltip" data-placement="left" title="7999320000" ><i class="bi bi-phone"></i></a>
      <a id="two" class="test" href="#"><i class="bi bi-globe2"></i></a>
      <a id="three" class="test" href="#"><i class="fas fa-vr-cardboard"></i></a>
      <a id="all" class="test" href="#"><i class="bi bi-whatsapp"></i></a>
      <span class="toggle-btn ion-android-funnel" ><i class="fas fa-bars"></i></span>
    </div>
</div> -->



<!-- Modal -->
<div class="modal fade" id="otpModal" tabindex="-1" aria-labelledby="otpModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="otpModalLabel">Number Verification</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- Angular Material Stepper -->
                <mat-horizontal-stepper linear #stepper>
                    <mat-step [stepControl]="firstFormGroup">
                        <form #phoneForm="ngForm" (ngSubmit)="onSubmitPhone(phoneForm)">
                            <ng-template matStepLabel>Fill out your Phone Number</ng-template>
                            <div class="form-group">
                                <label for="phone">Phone Number</label>
                                <input type="tel" class="form-control" id="phone" aria-describedby="phoneHelp"
                                    placeholder="Enter Phone Number" name="phone" ngModel required minlength="10"
                                    maxlength="10">
                                <small id="phoneHelp" class="form-text text-muted">We'll never share your Phone Number
                                    with anyone else.</small>
                            </div>
                            <button class="btn btn-primary" type="submit" [disabled]="!phoneForm.valid"
                                matStepperNext>Send</button>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="secondFormGroup">
                        <form #otpForm="ngForm" (ngSubmit)="onSubmitotp(otpForm)">
                            <ng-template matStepLabel>Fill OTP</ng-template>
                            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="verifyOtp">
                                Incorrect OTP. Please Try Again!
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="alert alert-success alert-dismissible fade show" role="Success" *ngIf="newOtp">
                                Please Enter New OTP!
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <div class="border-right pr-5">
                                    <label for="otp">Enter OTP</label>
                                    <input type="tel" class="form-control" id="otp" aria-describedby="otpHelp"
                                        placeholder="Enter OTP" name="otp" ngModel required minlength="4" maxlength="4">
                                    <small id="otpHelp" class="form-text text-muted">Enter OTP for Verification.</small>
                                </div>
                                <button class="btn btn-primary ml-5" type="button" *ngIf="showResend"
                                    [disabled]="resendButton" (click)="resendOtp()">Resend</button>
                            </div>
                            <button class="btn btn-primary " type="button" matStepperPrevious>Back</button>
                            <button class="btn btn-primary ml-2" type="submit"
                                [disabled]="!otpForm.valid">Verify</button>
                        </form>
                    </mat-step>

                </mat-horizontal-stepper>

                <!-- Stepper End -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>

<!-- <div id="naac">
      <img src="/assets/img/showcase/naac.png" alt="Naac" class="img-fluid rounded">
  </div> -->

<div class="modal" id="applyModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Apply for Admission now</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <button class="btn btn-primary mr-3" routerLink="enquiry-form" (click)="closeModal()">Enquiry</button>
                <button class="btn btn-primary mr-3" routerLink="apply-form" (click)="closeModal()">Registration
                    Form</button>
                <button class="btn btn-primary" (click)="admissionForm(3)">Job Application Form</button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="paymentModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Online Payment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <button class="btn btn-primary mr-3"
                    (click)="paymentUrl('https://app.okiedokiepay.com/#!/5ab9d6076d06bb016a97916f/entityLandingPage')">Grant-In-Aid
                    (GIA)</button>
                <button class="btn btn-primary"
                    (click)="paymentUrl('https://app.okiedokiepay.com/#!/61bc0f860cd5ab101bef072d/entityLandingPage')">Self
                    Finance</button> -->
                <button class="btn btn-primary mr-3"
                    (click)="paymentUrl('https://play.google.com/store/apps/details?id=khalsa.odlms.in')">Android
                    App</button>
                <button class="btn btn-primary"
                    (click)="paymentUrl('https://apps.apple.com/in/app/khalsa-learning-app/id6446783682')">IOS
                    App</button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>