<div id="lesson">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/courses/lesson-plan"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>LESSON PLAN(2023-24)</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">DEPARTMENT OF ZOOLOGY</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">DEPARTMENT OF SOCIAL WORK</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">DEPARTMENT OF SANSKRIT</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">DEPARTMENT OF PUNJABI</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">DEPARTMENT OF PUBLIC ADMINISTRATION</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">DEPARTMENT OF POLITICAL SCIENCE</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">DEPARTMENT OF PHYSICS</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">DEPARTMENT OF MUSIC</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('9')">DEPARTMENT OF MATHEMATICS</a>
                        </li>
                        <li class="ten">
                            <a (click)="changeRoute('10')">DEPARTMENT OF IMB</a>
                        </li>
                        <li class="eleven">
                            <a (click)="changeRoute('11')">DEPARTMENT OF IC</a>
                        </li>
                        <li class="twelve">
                            <a (click)="changeRoute('12')">DEPARTMENT OF HISTORY </a>
                        </li>
                        <li class="thirteen">
                            <a (click)="changeRoute('13')">DEPARTMENT OF HINDI</a>
                        </li>
                        <li class="fourteen">
                            <a (click)="changeRoute('14')">DEPARTMENT OF HEALTH PHYSICAL EDUCATION SPORTS</a>
                        </li>
                        <li class="fifteen">
                            <a (click)="changeRoute('15')">DEPARTMENT OF HEALTH PHYSICAL EDUCATION SPORTS-YOGA</a>
                        </li>
                        <li class="sixteen">
                            <a (click)="changeRoute('16')">DEPARTMENT OF GEOGRAPHY</a>
                        </li>
                        <li class="seventeen">
                            <a (click)="changeRoute('17')">DEPARTMENT OF ENGLISH</a>
                        </li>
                        <li class="eighteen">
                            <a (click)="changeRoute('18')">DEPARTMENT OF EDUCATION</a>
                        </li>
                        <li class="nineteen">
                            <a (click)="changeRoute('19')">DEPARTMENT OF ECONOMICS</a>
                        </li>
                        <li class="twenty">
                            <a (click)="changeRoute('20')">DEPARTMENT OF COMPUTER SCIENCE</a>
                        </li>
                        <li class="twentyone">
                            <a (click)="changeRoute('21')">DEPARTMENT OF COMMERCE</a>
                        </li>
                        <li class="twentytwo">
                            <a (click)="changeRoute('22')">DEPARTMENT OF CHEMISTRY</a>
                        </li>
                        <li class="twentythree">
                            <a (click)="changeRoute('23')">DEPARTMENT OF BOTANY</a>
                        </li>
                        <li class="twentyfour">
                            <a (click)="changeRoute('24')">DEPARTMENT OF BIOTECHNOLOGY</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div id="sports">
                    <div class="container-fluid pdf">
                        <div class="row">
                            <div class="col-12">
                                <div class="card shadow-lg">
                                    
                                    <h5 class="card-title text-center">LESSON PLAN(2023-24)</h5>
                                    <iframe [src]="src | safe" width="100%" height="1100" allow="autoplay"></iframe>
                
                            </div>
                        </div>
                
                       
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>