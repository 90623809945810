import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultComponent } from 'src/app/view/student-corner/consult/consult.component';
import { PropectusComponent } from 'src/app/view/student-corner/propectus/propectus.component';
import { RagingComponent } from 'src/app/view/student-corner/raging/raging.component';
import { StudentCornerComponent } from 'src/app/view/student-corner/student-corner.component';
import { RouterModule } from '@angular/router';
import { StudentCornerRoutingModule } from './student-corner-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoaderModule } from '../loader/loader.module';
import { ImportantLinkComponent } from 'src/app/view/student-corner/important-link/important-link.component';
import { GrieveanceComponent } from 'src/app/view/student-corner/grieveance/grieveance.component';
import { FeedbackComponent } from 'src/app/view/student-corner/feedback/feedback.component';
import { CoPoComponent } from 'src/app/view/student-corner/co-po/co-po.component';



@NgModule({
  declarations: [
    StudentCornerComponent,
    RagingComponent,
    PropectusComponent,
    ConsultComponent,
    ImportantLinkComponent,
    GrieveanceComponent,
    FeedbackComponent,
    CoPoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    StudentCornerRoutingModule,
    PdfViewerModule,
    LoaderModule
  ]
})
export class StudentCornerModule { }
