import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { CoPoComponent } from "src/app/view/student-corner/co-po/co-po.component";
import { ConsultComponent } from "src/app/view/student-corner/consult/consult.component";
import { FeedbackComponent } from "src/app/view/student-corner/feedback/feedback.component";
import { GrieveanceComponent } from "src/app/view/student-corner/grieveance/grieveance.component";
import { ImportantLinkComponent } from "src/app/view/student-corner/important-link/important-link.component";
import { PropectusComponent } from "src/app/view/student-corner/propectus/propectus.component";
import { RagingComponent } from "src/app/view/student-corner/raging/raging.component";
import { StudentCornerComponent } from "src/app/view/student-corner/student-corner.component";
const route:Route[]=[
    {path:'',component:StudentCornerComponent,children:[
        {path:'anti-ragging',component:RagingComponent},
        {path:'prospectus',component:PropectusComponent},
        {path:'consult',component:ConsultComponent},
        {path:'student-feedback',component:FeedbackComponent},
        {path:'important-links',component:ImportantLinkComponent},
        {path:'grievance',component:GrieveanceComponent},
        // {path:'co-po',component:CoPoComponent},
    ]}
]
@NgModule({
    imports:[
        RouterModule.forChild(route)
    ],
    exports:[
        RouterModule
    ]
})
export class StudentCornerRoutingModule{}